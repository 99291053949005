import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAuth } from "../../services/AuthContext";
import { Image, Button } from "antd";
import { Link } from "react-router-dom";
import Notification from "../../components/Notification";
import axios from "axios";
import { CheckOutlined } from "@ant-design/icons";

const Shop = () => {
  const [notification, setNotification] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(true);

  const settings = {
    infinite: true,
    arrows: false,
    slidesToShow: 10,
    slidesToScroll: 3,
    speed: 600,
  };

  const getAvatar = () => {
    axios.get("/auth/getAvatar").then(res => {
      setAvatar(res.data.link);
    }).catch(err => {
      console.log(err);
    })
  }

  useEffect(() => {
    getAvatar();
  }, [])

  return (
    <div className="p-6">
      {/* <div className="slider-container">
        <Slider {...settings}>
        </Slider>
      </div> */}
      <div className="flex items-center">
        <div className="w-fit h-full flex flex-col justify-between">
          <div className="w-fit">
            <div className="text-center text-xl text-yellow-400 font-semibold p-2">
              Selected Image
            </div>
            <Image className="border rounded-full" width={180} height={180} src={avatar}/>
          </div>
          <Link to="https://playxima.com/shop" target="_blink"><div className="rounded mt-24 bg-orange-400 text-center p-2 text-lg">Go to Shop</div></Link>
        </div>
        <div className="w-full px-8 flex flex-col justify-between">
          <div className="slider-container">
            <div className="font-bold text-white text-lg">FREE</div>
            <Slider {...settings}>
              {
                Array(40).fill(0).map((_, index) => {
                  const link = `https://juegoxima.com/images/free/${index % 6 + 1}.png`;
                  return <div className="max-w-[110px] p-2 flex flex-col items-center text-center mx-auto">
                    <img className="border w-full rounded-full mb-2" src={link}/>
                    <Button
                      style={{
                        backgroundColor: "#4caf50",
                        borderColor: "#4caf50",
                        color: "#fff",
                        borderRadius: "10px",
                        boxShadow: "0 3px 6px rgba(0,0,0,0.3)",
                      }}
                      disabled={link === avatar}
                      onClick={() => {
                        axios.post("/auth/changeAvatar", { link }).then((res) => {
                          setNotification({
                            type: "success",
                            message: "Changed",
                            description: "Image changed successfully.",
                          });
                          getAvatar();
                        }).catch(err => {
                          console.log(err);
                        });
                      }}
                    >
                    {link !== avatar ? <div>Select</div> : <div> <CheckOutlined /> Selected</div>}
                  </Button>
                  </div>
                })
              }
            </Slider>
        </div>
        <div className="slider-container">
          <div className="font-bold text-white text-lg">PREMIUM</div>
              <Slider {...settings}>
                {
                  Array(20).fill(0).map((_, index) => {
                    return <div className="max-w-[110px] p-2 flex flex-col items-center text-center mx-auto">
                      <img className="border w-full rounded-full mb-2" src={`https://juegoxima.com/images/pay/${index + 1}.png`}/>
                      <Button type="primary">PAY</Button>
                    </div>
                  })
                }
              </Slider>
          </div>

          <div className="slider-container">
          <div className="font-bold text-white text-lg">PROFESSIONAL</div>
              <Slider {...settings}>
                {
                  Array(20).fill(0).map((_, index) => {
                    return <div className="max-w-[110px] p-2 flex flex-col items-center text-center mx-auto">
                      <img className="border w-full rounded-full mb-2" src={`https://juegoxima.com/images/pay/${index + 1}.png`}/>
                      <Button type="primary">PAY</Button>
                    </div>
                  })
                }
              </Slider>
          </div>
        </div>
        
      </div>

      {notification && (
        <Notification
          type={notification.type}
          message={notification.message}
          description={notification.description}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  );
};

export default Shop;
