import React from "react";
import { HomeFilled } from "@ant-design/icons";
import { Link } from "react-router-dom"
import { GiTrophyCup } from "react-icons/gi";
import { FaShopify } from "react-icons/fa";
import { PiGraduationCapFill } from "react-icons/pi";
import { IoDiamondOutline } from "react-icons/io5";

const Sidebar = () => {
  return (
    <div className="flex flex-col gap-8 items-center border-gray-400 justify-center min-w-28 w-28 h-full bg-gray-400 bg-gradient-to-b from-gray-900 to-blue-900 border-r shadow-md">
      <Link to="/home"><div className="flex text-white flex-col items-center gap-2"><HomeFilled style={{ fontSize: '32px', color: '#fff' }} />Home</div>
      </Link>
      <Link to="/game"><div className="flex text-white flex-col items-center gap-2"><GiTrophyCup style={{ fontSize: '48px', color: '#fff' }} />Tournament</div>
      </Link>
      <Link to="/game/shop"><div className="flex text-white flex-col items-center gap-2"><FaShopify style={{ fontSize: '32px', color: '#fff' }} />Shop</div>
      </Link>
      <Link to="https://playxima.com" target="_blank"><div className="flex text-white flex-col items-center gap-2"><PiGraduationCapFill style={{ fontSize: '32px', color: '#fff' }} />Learning</div>
      </Link>
      <Link to="/home"><div className="flex text-white flex-col items-center gap-2"><IoDiamondOutline style={{ fontSize: '32px', color: '#fff' }} />Premium</div>
      </Link>
    </div>
  );
};

export default Sidebar;
