import React, { useEffect, useState } from "react";
import {
  Table,
  ConfigProvider,
  theme,
  Modal,
  Divider,
  Input,
  DatePicker,
  Select,
  Button,
  Pagination,
  notification,
  Tag,
} from "antd";
import axios from "axios";
import moment from "moment";
import Logo from "../../images/logo.png";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import { IoDiamondOutline } from "react-icons/io5";

const socket = io("https://juegoxima.com");
socket.on("connect", () => {
  console.log("Connected to Socket.IO server");
});

const Index = () => {
  const [open, setOpen] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [filteredTournaments, setFilteredTournaments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    tournamentName: "",
    startingTime: moment(), // Set default to current time using moment
    duration: "1", // Default duration to 1 day
    havingTime: "1", // Default having time to 1 minute
    mode: "dice",
    memberSize: 2,
  });

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const handleJoin = (id) => {
    axios
      .post("/tournaments/join", { id })
      .then(() => {
        navigate(`/game/${id}`);
        localStorage.setItem("tId", id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCreateTournament = async () => {
    const { tournamentName, startingTime, duration, havingTime } = formData;

    // Validation
    if (!tournamentName) {
      notification.error({
        message: "Validation Error",
        description: "Tournament name is required.",
      });
      return;
    }

    const currentTime = moment();
    if (startingTime.isBefore(currentTime)) {
      notification.error({
        message: "Validation Error",
        description:
          "Starting time must be set to the current time or in the future.",
      });
      return;
    }

    if (duration < 1) {
      notification.error({
        message: "Validation Error",
        description: "Duration must be at least 1 day.",
      });
      return;
    }

    if (havingTime < 1 || havingTime > 5) {
      notification.error({
        message: "Validation Error",
        description: "Having time must be between 1 and 5 minutes.",
      });
      return;
    }

    try {
      const response = await axios.post("/tournaments", {
        ...formData,
        startingTime: startingTime.toISOString(), // Convert moment to ISO string
      });
      const data = response.data;

      // socket.emit("newTournament", data);
      notification.success({
        message: "Tournament Created",
        description: "The tournament has been created successfully.",
      });

      handleClose();
      fetchTournaments(); // Fetch tournaments after creating a new one
    } catch (error) {
      console.error("Error creating tournament:", error);
      notification.error({
        message: "Error",
        description: "Failed to create the tournament.",
      });
    }
  };

  const fetchTournaments = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/tournaments");
      setLoading(false);
      const data = response.data;
      setTournaments(data);
      applyFilter(data, filter);
    } catch (error) {
      console.error("Error fetching tournaments:", error);
    }
  };

  const applyFilter = (data, filter) => {
    let filtered = data;

    if (filter === "playing") {
      const currentTime = moment();
      filtered = data.filter((tournament) => {
        const startingTime = new Date(tournament.startingTime);
        const endTime = moment(
          startingTime.valueOf() +
            Number(tournament.duration) * 3600 * 24 * 1000
        );
        return (
          currentTime.isBefore(endTime) &&
          moment(startingTime.valueOf()).isBefore(currentTime)
        );
      });
    } else if (filter === "expired") {
      const currentTime = moment();
      filtered = data.filter((tournament) => {
        const startingTime = new Date(tournament.startingTime);
        const endTime = moment(
          startingTime.valueOf() +
            Number(tournament.duration) * 3600 * 24 * 1000
        );
        return currentTime.isAfter(endTime);
      });
    } else if (filter === "waiting") {
      const currentTime = moment();
      filtered = data.filter((tournament) => {
        const startingTime = new Date(tournament.startingTime);
        return moment(startingTime.valueOf()).isAfter(currentTime);
      });
    }

    setFilteredTournaments(filtered);
    setCurrentPage(1); // Reset to the first page when filtering
  };

  useEffect(() => {
    fetchTournaments().catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    window.socket = socket;

    socket.on("tournamentCreated", (newTournament) => {
      console.log(newTournament);
      setTournaments((prev) => [...prev, newTournament]);
      applyFilter([...tournaments, newTournament], filter);
    });

    socket.on("USER_ATTEND", (data) => {
      const parse = JSON.parse(data);
      let newTournaments = [...tournaments];
      tournaments.forEach((tournament, index) => {
        if (tournament._id === parse.tournamentId) {
          tournament.users.forEach((user, i) => {
            if (user.userId === parse.userId) {
              newTournaments[index].users[i].state = true;
            }
          });
        }
      });
      console.log(newTournaments);
      setTournaments(newTournaments);
      applyFilter(newTournaments, filter);
    });

    socket.on("USER_DISCONNECTED", (data) => {
      const parse = JSON.parse(data);
      let newTournaments = [...tournaments];
      tournaments.forEach((tournament, index) => {
        if (tournament._id === parse.tournamentId) {
          tournament.users.forEach((user, i) => {
            if (user.userId === parse.userId) {
              newTournaments[index].users[i].state = false;
            }
          });
        }
      });

      setTournaments(newTournaments);
      applyFilter(newTournaments, filter);
    });

    return () => {
      socket.off("tournamentCreated");
      socket.off("USER_ATTEND");
      socket.off("USER_DISCONNECTED");
    };
  }, [tournaments, filter]);

  // Columns for the Ant Design Table
  const columns = [
    {
      title: "Tournament Name",
      dataIndex: "tournamentName",
      key: "tournamentName",
    },
    {
      title: "Starting Time",
      dataIndex: "startingTime",
      key: "startingTime",
      render: (text) => (
        <span>
          {moment(text).format("YYYY-MM-DD HH:mm")}{" "}
          {/* Format to exclude seconds */}
        </span>
      ),
    },
    {
      title: "Duration (days)",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
    },
    {
      title: "Member Size",
      dataIndex: "memberSize",
      key: "memberSize",
    },
    {
      title: "Playing",
      key: "playing",
      render: (text, record) => {
        let i = 0;
        record.users.forEach((user) => {
          if (user.state) i++;
        });
        return i;
      },
    },
    {
      title: "Status",
      key: "status",
      render: (text, record) => {
        const currentTime = moment();
        const startingTime = new Date(record.startingTime);
        const endTime = moment(
          startingTime.valueOf() + Number(record.duration) * 3600 * 24 * 1000
        );
        if (currentTime.isAfter(endTime)) {
          return <Tag color="red">Finished</Tag>;
        } else if (moment(record.startingTime).isAfter(currentTime)) {
          return <Tag color="orange">Waiting</Tag>;
        } else return <Tag color="blue">Playing</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        const currentTime = moment();
        const startingTime = new Date(record.startingTime);
        const endTime = moment(
          startingTime.valueOf() + Number(record.duration) * 3600 * 24 * 1000
        );

        if (currentTime.isAfter(endTime)) {
          return (
            <Button
              className="w-20"
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                handleJoin(record._id);
              }}
            >
              View
            </Button>
          );
        } else if (moment(record.startingTime).isAfter(currentTime)) {
          return (
            <Button className="w-20" type="dashed">
              Join
            </Button>
          );
        } else
          return (
            <Button
              className="w-20"
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                handleJoin(record._id);
              }}
            >
              Join
            </Button>
          );
      },
    },
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "rgb(80, 80, 200)", // Rich purple for a mystical touch
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className="relative h-full p-8">
        <div className="flex items-center justify-between">
          <div className="flex space-x-4">
            <Select
              defaultValue="all"
              className="w-40 text-white rounded-lg bg-gradient-to-r from-blue-600 to-blue-700"
              dropdownClassName="rounded-lg bg-gray-800 text-white"
              onChange={(value) => {
                setFilter(value);
                applyFilter(tournaments, value);
              }}
            >
              <Select.Option value="all">All Tournaments</Select.Option>
              <Select.Option value="playing">Playing</Select.Option>
              <Select.Option value="expired">Expired</Select.Option>
              <Select.Option value="waiting">Waiting</Select.Option>
            </Select>
          </div>
          <button
            onClick={() => setOpen(true)}
            className="h-10 flex items-center gap-2 px-4 py-2 font-bold text-white transition duration-200 rounded-lg bg-gradient-to-r from-orange-500 to-yellow-500 hover:from-orange-600 hover:to-yellow-600 animate-pulse"
          >
            <IoDiamondOutline /> Create Tournament
          </button>
        </div>

        <div className="max-h-[90%] bg-opacity-65 h-full mt-6 overflow-y-hidden border border-blue-600 rounded-lg bg-gray-800 shadow-lg">
          <Table
            dataSource={filteredTournaments.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )}
            scroll={{ x: "max-content" }}
            columns={columns}
            pagination={false}
            className="text-white"
            loading={loading}
            locale={{ emptyText: "No Available Tournaments" }}
          />
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={filteredTournaments.length}
            onChange={(page) => setCurrentPage(page)}
            className="mt-4 text-white"
          />
        </div>

        <div className="absolute bottom-4 right-5">
          <img src={Logo} width={150} alt="Fantasy Logo" />
        </div>
      </div>

      <Modal
        open={open}
        onCancel={handleClose}
        footer={null}
        maskClosable={false}
        className="text-white bg-gray-800 rounded-lg"
      >
        <div className="text-lg font-bold text-yellow-400">
          ⚔️ Create a New Tournament
        </div>
        <Divider className="my-2 border-yellow-600" />

        <div className="mb-4">
          <label className="block mb-1 text-gray-300">Tournament Name</label>
          <Input
            placeholder="Enter Tournament Name"
            value={formData.tournamentName}
            onChange={(e) =>
              setFormData({ ...formData, tournamentName: e.target.value })
            }
            className="text-white bg-gray-700 border-none rounded-lg"
            style={{ height: "40px" }}
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1 text-gray-300">Start Time</label>
          <DatePicker
            className="w-full text-white bg-gray-700 rounded-lg"
            showTime
            onChange={(date) =>
              setFormData({ ...formData, startingTime: date })
            }
            disabledDate={(current) =>
              current && current < moment().startOf("minute")
            }
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1 text-gray-300">Duration (in days)</label>
          <Input
            placeholder="Enter Duration"
            type="number"
            value={formData.duration}
            onChange={(e) =>
              setFormData({ ...formData, duration: e.target.value })
            }
            className="text-white bg-gray-700 rounded-lg"
            style={{ height: "40px" }}
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1 text-gray-300">
            Having Time (in minutes)
          </label>
          <Input
            placeholder="Enter Having Time"
            type="number"
            value={formData.havingTime}
            onChange={(e) =>
              setFormData({ ...formData, havingTime: e.target.value })
            }
            className="text-white bg-gray-700 rounded-lg"
            style={{ height: "40px" }}
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1 text-gray-300">Mode</label>
          <Select
            value={formData.mode}
            onChange={(value) => setFormData({ ...formData, mode: value })}
            className="w-full text-white bg-gray-700 rounded-lg"
          >
            <Select.Option value="dice">Dice Mode</Select.Option>
            <Select.Option value="free">Free Mode</Select.Option>
          </Select>
        </div>

        <div className="mb-4">
          <label className="block mb-1 text-gray-300">Member Size</label>
          <Select
            defaultValue={2}
            className="w-full text-white bg-gray-700 rounded-lg"
            onChange={(value) =>
              setFormData({ ...formData, memberSize: value })
            }
          >
            <Select.Option value={2}>2</Select.Option>
            <Select.Option value={3}>3</Select.Option>
            <Select.Option value={4}>4</Select.Option>
          </Select>
        </div>

        <Button
          type="primary"
          onClick={handleCreateTournament}
          className="w-full text-white bg-yellow-600 hover:bg-yellow-700"
        >
          Create Tournament
        </Button>
      </Modal>
    </ConfigProvider>
  );
};

export default Index;
