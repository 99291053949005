import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";

const GameLayout = () => {
  return (
    <div
      className="flex h-screen bg-gradient-to-b from-gray-900 to-blue-900"
    >
      <div className="flex w-full">
        <Sidebar />
        <div className="w-full">
          <Outlet /> {/* This renders the child routes */}
        </div>
      </div>
    </div>
  );
};

export default GameLayout;
