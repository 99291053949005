import React, { useEffect, useState } from "react";
import {
  Button,
  ConfigProvider,
  theme,
  Table,
  Card,
  Drawer,
  Tag,
  Spin,
  Modal,
  Badge,
  FloatButton,
} from "antd";
import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";

import { Link, useParams, useNavigate } from "react-router-dom";
import { IoTimeOutline } from "react-icons/io5";
import { TiUserOutline } from "react-icons/ti";
import { PiRankingDuotone } from "react-icons/pi";
import Moment from "react-moment";
import axios from "axios";
import { useAuth } from "../../services/AuthContext";
import moment from "moment";
import Notification from "../../components/Notification";

import "./card.css";

const Tournament = () => {
  const [open, setOpen] = useState(false);
  const [roomId, setRoomId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [expired, setExpired] = useState(false);
  const [data, setData] = useState([]);
  const { user } = useAuth();
  const { id } = useParams();
  const [iframeSrc, setIframeSrc] = useState("/Xima_build/index.html");
  const [notification, setNotification] = useState(null);
  const [drawer, setDrawer] = useState(false);

  const reloadIframe = () => {
    setLoading(true);
    // Update the src to trigger a reload
    setIframeSrc("");
    // Restore the original src after a brief delay
    setTimeout(() => setIframeSrc("/Xima_build/index.html"), 0);
    setRoomId(0);
  };

  const [tourInfo, setTourInfo] = useState({
    name: "",
    mode: "",
    start: "",
    members: 0,
    duration: 0,
    users: [],
    ranking: 0,
    rank: 1,
  });
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`/tournaments/${id}`)
      .then((res) => {
        if (!res.data.success) {
          navigate("/game");
        } else {
          setTourInfo(res.data);
          const currentTime = moment();
          const startingTime = new Date(res.data.start);
          const endTime = moment(
            startingTime.valueOf() +
              Number(res.data.duration) * 3600 * 24 * 1000
          );
          if (currentTime.isAfter(endTime)) {
            setExpired(true);
          }
          setData(res.data.users);
        }
      })
      .catch((err) => {
        navigate("/game");
        console.log(err);
      });
    if (window.socket) {
      window.socket.off("USER_START");
      window.socket.off("USER_ATTEND");
      window.socket.off("OUT_ROOM");
      window.socket.off("USER_DISCONNECTED");

      window.socket.on("OUT_ROOM", (userId) => {
        if (user.email === userId) {
          reloadIframe();
        }
      });

      window.socket.on("USER_START", (userId) => {
        if (user.email === userId) {
          setOpen(false);
          setLoading(true);
        }
      });

      window.socket.on("USER_ATTEND", (data) => {
        const parse = JSON.parse(data);
        console.log(user.email);
        console.log(parse);
        if (user.email === parse.userId && parse.tournamentId === id) {
          setLoading(false);
        }

        if (parse.tournamentId === id) {
          setData((data) => {
            let isContained = false;
            const newData = [...data];
            data.forEach((user, index) => {
              if (user.userId === parse.userId) {
                isContained = true;
                newData[index].state = true;
              }
            });

            if (!isContained)
              return [
                ...data,
                {
                  userId: parse.userId,
                  ranking: 0,
                  state: true,
                },
              ];
            else return newData;
          });
        }
      });

      window.socket.on("USER_DISCONNECTED", (data) => {
        const parse = JSON.parse(data);

        if (parse.tournamentId === id) {
          setNotification({
            type: "error",
            message: "Disconnected",
            description: `${parse.userId} is disconnected from game.`,
          });

          setData((data) => {
            const newData = [...data];
            data.forEach((user, index) => {
              if (user.userId === parse.userId) {
                newData[index].state = false;
              }
            });

            return newData;
          });
        }
      });
    } else {
      navigate("/game");
    }
  }, []);

  useEffect(() => {
    if (window.socket) {
      window.socket.off("ADD_BLOCK_DECLINE");
      window.socket.off("GAME_END");

      window.socket.on("ADD_BLOCK_DECLINE", (data) => {
        const parse = JSON.parse(data);
        if (
          parse.tournamentId === id &&
          String(parse.roomId) === String(roomId)
        ) {
          if (user.email !== parse.userId) {
            setNotification({
              type: "error",
              message: "Decline",
              description: `${parse.userId} decline for adding 3 blocks`,
            });
          }
        }
      });

      window.socket.on("GAME_END", (data) => {
        const parse = JSON.parse(data);

        if (parse.tournamentId === id && parse.winnerId) {
          setData((users) => {
            const newUsers = users.map((user) => {
              if (user.userId === parse.winnerId) {
                return {
                  ...user,
                  userId: user.userId,
                  ranking: user.ranking + 2,
                };
              } else return user;
            });

            const sorted = newUsers.sort(
              (val1, val2) => val2.ranking - val1.ranking
            );

            sorted.forEach((data, index) => {
              if (data.userId === user.email) {
                setTourInfo((tourInfo) => {
                  return {
                    ...tourInfo,
                    rank: index + 1,
                    ranking: data.ranking,
                  };
                });
              }
            });

            return sorted;
          });
        }

        if (
          parse.tournamentId === id &&
          String(parse.roomId) === String(roomId)
        ) {
          setTimeout(() => {
            reloadIframe();
            // setLoading(false);
          }, 3000);

          if (!parse.winnerId) {
            setNotification({
              type: "success",
              message: "Finished",
              description: `Game is finished because all other members were disconnected.`,
            });
          }
        }
      });
    } else {
      navigate("/game");
    }
  }, [roomId]);

  const columns = [
    {
      title: "Nickname",
      key: "nickname",
      render: (text, record) => {
        return (
          <div>
            <Badge status={record.state ? "success" : "error"} />{" "}
            {record.userId}
          </div>
        );
      },
    },
    {
      title: "Score",
      dataIndex: "ranking",
      key: "score",
      width: 110,
    },
  ];

  const onDrawerClose = () => {
    setDrawer(false);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "rgb(59, 130, 246)", // Mystical blue color
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className="relative flex flex-col justify-between h-full p-6 md:flex-row md:p-12 bg-gradient-to-b from-gray-900 via-gray-800 to-blue-900 flex-items font-fantasy">
        <div className="relative w-full md:w-[68%] h-[80vh] md:h-full flex flex-col justify-center border-2 border-white bg-gradient-to-r from-blue-900 to-indigo-800 bg-center bg-cover shadow-2xl rounded-lg overflow-hidden">
          <iframe src={iframeSrc} className="w-full h-full border-none" />
        </div>

        <div className="w-full md:w-[30%] mt-6 md:mt-0 text-white bg-gradient-to-b from-gray-800 to-gray-900 p-4 md:p-8 rounded-2xl border-2 border-white border-opacity-50 shadow-xl">
          <div className="flex flex-col justify-between h-full">
            <div>
              {/* <Card className="game-card"> */}
              <div className="flex flex-col items-center justify-between mb-2 lg:flex-row card-header">
                <div className="font-bold card-title">{tourInfo.name}</div>
                <Tag className="mode-tag" color="purple">
                  {tourInfo.mode.toUpperCase()}
                </Tag>
              </div>
              <div className="text-xl text-center">
                {user.email} ({tourInfo.ranking} points)
              </div>
              <div className="flex items-center justify-between mb-2 card-info">
                <div className="flex items-center ranking-info">
                  <PiRankingDuotone className="icon-glow" />
                  <span className="ml-2">{tourInfo.rank}</span>
                </div>
                <div className="flex items-center user-info">
                  <TiUserOutline className="icon-glow" />
                  <span className="ml-2 mr-4">{tourInfo.users.length}</span>
                </div>
              </div>
              <div className="flex items-center mb-2 time-info">
                <IoTimeOutline className="icon-glow" />
                <span className="ml-2">
                  <Moment format="YYYY-MM-DD hh:mm">{tourInfo.start}</Moment> -{" "}
                  {tourInfo.duration} days
                </span>
              </div>
              <Button
                className="w-full play-button"
                size="large"
                type="primary"
                disabled={loading || expired}
                onClick={() => {
                  setLoading(true);
                  setOpen(true);
                  setTimeout(() => {
                    axios
                      .post("/tournaments/play", { id })
                      .then((res) => {
                        if (res.data.roomId !== -1) {
                          setRoomId(res.data.roomId);
                          console.log(res.data.roomId);
                        } else {
                          setLoading(true);
                          setOpen(false);
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }, 1000);
                }}
              >
                {expired ? "Expired" : "Play"}
              </Button>
              {/* </Card> */}

              {/* Table with horizontal scrollbar for overflow */}
              {/* <div className="hidden mt-2 lg:block">
                <Table
                  size="small"
                  scroll={{ x: "max-content", y: 50 * 4 }}
                  columns={columns}
                  pagination={false}
                  dataSource={data}
                />
              </div> */}
            </div>

            {/* <div className="flex justify-between w-full mt-4 text-xs text-blue-400">
              <div className="transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105">
                <Link to="https://playxima.com" target="_blank">
                  How to play XIMA
                </Link>
              </div>
              <Link
                to="/game"
                className="transition duration-200 ease-in-out transform hover:text-blue-500 hover:scale-105"
              >
                Back
              </Link>
            </div> */}
          </div>
        </div>
      </div>

      <Drawer title="Users" onClose={onDrawerClose} open={drawer}>
        <Table
          size="small"
          columns={columns}
          pagination={false}
          dataSource={data}
        />
      </Drawer>

      {notification && (
        <Notification
          type={notification.type}
          message={notification.message}
          description={notification.description}
          onClose={() => setNotification(null)}
        />
      )}

      <Modal
        centered
        open={open}
        footer={false}
        width={300}
        onCancel={() => {
          setOpen(false);
          setLoading(false);
          if (window.socket) {
            window.socket.emit(
              "OUT_TOURNAMENT",
              JSON.stringify({ tournamentId: id, roomId, userId: user.email })
            );
            setRoomId(0);
          }
        }}
        maskClosable={false}
        className="text-white shadow-lg bg-gradient-to-b from-gray-800 to-gray-900 rounded-2xl"
      >
        <div className="w-full text-center card-header">
          <div className="mb-4 text-2xl">{tourInfo.name}</div>
          <Spin size="large" />
          <div className="mt-2 mb-4 text-lg font-semibold text-blue-400 animate-pulse">
            Finding user...
          </div>
        </div>
      </Modal>
      <FloatButton.Group shape="circle" style={{ insetInlineEnd: 35 }}>
        <FloatButton
          type="primary"
          icon={<UserOutlined />}
          onClick={() => {
            setDrawer(true);
          }}
        />
        <FloatButton
          onClick={() => {
            navigate("/game");
          }}
          type="primary"
          icon={<ArrowLeftOutlined />}
        />
      </FloatButton.Group>
    </ConfigProvider>
  );
};

export default Tournament;
